

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "../utils/Token";
import axios from "axios";

const Header = (props) => {
    // const [isClicked, setIsClicked] = useState(false);

    // const handleClick = () => {
    //     setIsClicked(!isClicked);
    // };
    let userid = localStorage.getItem("userid")
    let token = localStorage.getItem("token")
    const [profileimg, setprofileimg] = useState("")
    const [data, setdata] = useState([])
    const [name, setname] = useState("")
    const headers = {
        Authorization: `Bearer ${token}`
    }
    const handleprofile = () => {
        axios.get(`${BaseUrl}/fetch_users_admin?id=${userid}`, { headers }).then((res) => {
            // console.log(res)
            let data = res.data.data
            setdata(data)
            setprofileimg(data?.image)
            setname(data.business_owner_fn)
            console.log(data)


        })
    }

    useEffect(() => {
        handleprofile()
    }, [])

    return (
        <nav className={props.className} >
            <div className="container-fluid">
                <button
                    className={`navbar-toggler mobilenav `}
                    type="button"
                    onClick={props.handleClick}
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >

                </button>
                <div className="collapse navbar-collapse p-2 " id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mt-2 mt-lg-0">

                        <li >
                            <Link to="/profile">
                                <div className="d-flex align-items-center gap-2 bg-white shadow rounded-3 p-2">
                                    <img
                                        src={`${BaseUrl}/${profileimg}`}
                                        onError={(e) => {
                                            e.target.src = "https://via.placeholder.com/150";
                                        }}
                                        className="rounded-circle"
                                        style={{ height: "45px", width: "45px" }}
                                        alt="Profile"
                                    />
                                    <h6 className="mb-0  ">{name}</h6>
                                </div>
                            </Link>

                        </li>

                    </ul>
                </div>
            </div>
        </nav>

    );
};

export default Header;
