
import React, { useEffect, useState } from "react";
import "../../assets/css/login.css";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup } from "react-bootstrap";
import authLayout from "../../hoc/authLayout";
import axios from "axios";
import OtpInput from 'react-otp-input'
import logo from "../../assets/images/logo.png"
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { BaseUrl } from "../../utils/Token";


const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [error, seterror] = useState(true)
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const navigate = useNavigate();

    const handleClick = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(`${BaseUrl}/enter_username`, {
                username: username
            });
            setMessage(response.data.message);
            setStatus(response.data.status);
            console.log(response.data.error);
            if (response.data.is_email_verified == false) {
                navigate(`/email-verification/${username}/${response.data._id}`, { state: response.data.message })

            }

            if (response.data.error == '1') {
                toast.error("You are not Authorized")
                seterror(false)
            } else {
                setIsClicked(true);
                seterror(true)
            }

        } catch (error) {
            console.error(error);
        }
    };

    const handleOtpClick = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${BaseUrl}/authenticated_login`, {
                username: username,
                otp: otp
            });

            if (response.status === 200) {



                if (Number(otp) === message) {



                    if (response.data.data.type == "Vendor") {
                        const token = response.data.token;
                        localStorage.setItem('token', token)
                        localStorage.setItem("userid", response.data.data._id)
                        navigate("/dashboard");
                        window.location.reload()
                    } else {
                        toast.error("You are not allow to Login")
                    }




                } else {
                    toast.error("it does not mathces")
                    // console.log(typeof otp)
                    // console.log(typeof message)
                }
                // Redirect to the dashboard page
            } else {
                // Handle login error              
                console.log(response);
                toast.error("Login failed. Please check your credentials.");
            }
        } catch (error) {
            console.error("Login error:", error);
            toast.error("An error occurred while trying to login. Please try again later.");
        }
    };

    const handleChange = (event) => {
        setUsername(event.target.value);
    };


    return (
        <>
            <ToastContainer />
            <h3 className="outletheading">
                <img src={logo} style={{ width: "350px" }} className="img-fluid"></img>
            </h3>
            {isClicked ? (
                <>
                    <Form onSubmit={handleOtpClick} className="login-form">

                        <div className="d-flex align-items-center my-4 justify-content-center w-100">
                            <h1 className="text-center fw-normal mb-0 me-3" style={{ color: "#1D5D9B" }}>Sign In</h1>
                        </div>
                        <FormGroup className="mt-3 OtpBox">
                            <label className="mb-1 fw-bold" htmlFor="otp">
                                OTP
                            </label>
                            {/* <input
                                type="text"
                                className="form-control"
                                placeholder="OTP"
                                required
                                id="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            // onChange={handleOtp}
                            /> */}
                            <OtpInput
                                value={otp}
                                onChange={setOtp}

                                numInputs={6}
                                className="form-control "
                                renderSeparator={<span className="me-2"> </span>}
                                renderInput={(props) => <input {...props} />}
                            />
                        </FormGroup>
                        <button className="btn mt-3 auth_btn w-100" type="submit">
                            Login
                        </button>
                    </Form>
                </>
            ) : (
                <>
                    <Form onSubmit={handleClick} className="login-form">

                        <div className="d-flex align-items-center my-4 justify-content-center w-100">

                            <h1 className="text-center fw-normal mb-0 me-3" style={{ color: "#1D5D9B" }}>Sign In</h1>
                        </div>
                        <FormGroup className="mt-3">
                            <label className="mb-1 fw-bold mb-2" htmlFor="name">
                                Phone Number
                            </label>
                            <input
                                type="text"
                                autoFocus
                                className="form-control"
                                placeholder="Phone No. With Country Code"
                                required
                                id="name"
                                value={username}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <button className="btn mt-3 mb-2 auth_btn w-100" type="submit">
                            Login
                        </button>
                        <Link to={'/registration'} className="text-primary" style={{ fontSize: "14px" }}>Create New Account ?</Link>
                    </Form>

                </>
            )}

            {error && (message && <p>Otp: {message}</p>)}

            {/* {status && <p>Status: {status}</p>} */}

        </>
    );
};

export default authLayout(LoginPage);

