import React, { useEffect, useState } from 'react'
import email from '../assets/images/email.jpg'
import OtpInput from 'react-otp-input'
import { Form, FormGroup } from 'react-bootstrap'
import axios from 'axios'
import { BaseUrl } from '../utils/Token'
import { ToastContainer, toast } from 'react-toastify'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function EmailVerification() {
    const [otp, setOtp] = useState('')
    const navigate = useNavigate()
    const { unique, id } = useParams()

    const { state } = useLocation()

    useEffect(() => {
        if (state) {
            toast.success(state)
        }
    }, [state])
    const handleSubmit = (e) => {
        e.preventDefault()
        let requestdata = {
            username: unique,
            otp: otp
        }
        axios.post(`${BaseUrl}/authenticated_login`, requestdata).then((res) => {
            if (res.data.status == "OK") {

                const token = res.data.token;
                handleUserForm(token)
                navigate("/");
                // window.location.reload()

            }
        }).catch(() => {
            toast.error("OTP Mismatch")
        })


    }




    const handleUserForm = async (token) => {



        const formData = new FormData();
        formData.append("_id", id);
        formData.append("is_email_verified", true);

        try {
            const response = await axios.put(`${BaseUrl}/update_user_admin`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Handle successful response
            console.log(response.data);
            if (response.data.status === "OK") {
                toast.success("Email Verified Successfully")
            } else {

                toast.error("Data not updated");
            }
        } catch (error) {
            // Handle error
            console.error(error);
            toast.error("An error occurred while updating data");
        } finally {

        }
    };

    return (
        <>
            <ToastContainer />
            <section className='mt-5'>
                <div className="container">
                    <div className="row align-items-center justify-content-center ">
                        <div className="col-md-5">
                            <div className="bg-white shadow p-3 rounded-4 text-center">
                                <img src={email} className='img-fluid' alt="" />
                                <h3 className='stylefont'>
                                    An OTP has been sent to your email. Please kindly verify your email.
                                </h3>

                                <Form onSubmit={(e) => handleSubmit(e)}>
                                    <div className="d-flex justify-content-center">
                                        <FormGroup className="mt-3 OtpBox text-center mb-3">
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={6}
                                                className="form-control "
                                                renderSeparator={<span className="me-2"> </span>}
                                                renderInput={(props) => <input {...props} />}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="">
                                        <button className='btn bg-success text-white me-3 w-100'>Verify OTP</button>
                                        {/* <button className='btn bg-dark text-white' onClick={(e) => window.location.reload()}>Resend OTP</button> */}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmailVerification
