import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import adminLayout from '../../hoc/adminLayout';

import axios from 'axios';
import { BaseUrl } from '../../utils/Token';
import { Bar, Line } from 'react-chartjs-2';

import product from "../../assets/images/product.png"

import orders from "../../assets/images/orders.png"
import brand from "../../assets/images/brand.png"
import delievery from "../../assets/images/delievery.png"
import cancelled from "../../assets/images/cancelled.png"
import returned from "../../assets/images/returned.png"
import { useNavigate } from 'react-router-dom';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';


// Register necessary components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function DashPage() {
    const chartRef = useRef(null);
    let token = localStorage.getItem("token")
    const navigate = useNavigate()
    const chartInstanceRef = useRef(null);
    const [data, setdata] = useState([])
    const headers = {
        Authorization: `Bearer ${token}`
    }

    const handledash = () => {
        axios.get(`${BaseUrl}/vendor_dashboard`, { headers }).then((res) => {
            console.log(res.data.data)
            setdata(res.data.data)
        })
    }

    useEffect(() => {
        handledash()
    }, [])

    const graphRef = useRef(null)





    useEffect(() => {
        if (chartRef.current) {

            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            const ctx = chartRef.current.getContext('2d');
            chartInstanceRef.current = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: ['Product', 'Orders', 'cancelled'],
                    datasets: [{
                        label: 'Data',
                        data: [data?.products, data?.order, data?.cancelled], // Replace with your actual data
                        backgroundColor: [
                            'rgb(0, 170, 150)',
                            'rgb(185, 224, 224)',
                            'rgba(255, 0, 0 , 0.6)'
                        ],

                        hoverOffset: 4
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            });
        }

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [data]);





    const boxdata = [
        {
            title: "Total Items",
            image: product,
            type: "products",
            color: "false",
            link: "/viewproduct"
        },
        // {
        //     title: "Total User",

        //     type: "users",
        //     color: "false"
        // },
        // {
        //     title: "Total Vendor",

        //     type: "vendor",
        //     color: "false"
        // },
        {
            title: "Total Orders",
            image: orders,
            type: "order",
            color: "false",
            link: '/orderlist',
        },
        {
            title: "Total Brand",
            image: brand,
            type: "brands",
            color: "false",
            link: "/brandlist"
        },

        {
            title: "Delivered Orders",
            image: delievery,
            type: "delivered",
            color: "false",
            link: "/orderlist/Delivered"
        },
        {
            title: "Cancelled Orders",
            image: cancelled,
            type: "cancelled",
            color: "true",
            link: "/orderlist/Cancelled"
        },
        // {
        //     title: "Today Sale",
        //     
        //     color: "false"
        // },
        // {
        //     title: "Top Selling Items",
        //     number: "34",
        //     color: "false"
        // },
        // {
        //     title: "Most Rated Item",
        //     number: "34",
        //     color: "false"
        // },
        // {
        //     title: "Total Money",
        //     number: "34",
        //     color: "false"
        // },
        {
            title: "Returned",
            number: "34",
            image: returned,
            type: "returned",
            color: "true",
            link: "/orderlist/Returned"
        }
    ];



    const lineData = {
        labels: data.net_sales_by_date?.map(sale => sale._id.date),
        datasets: [
            {
                label: 'Net Sales by Date',
                data: data.net_sales_by_date?.map(sale => sale.total),
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
            },
        ],
    };

    const net_SalesPending = {
        labels: "Net Sale Pending",
        datasets: [
            {
                label: 'Net Sales Pending',
                data: data.net_sales_pending?.map(item => item.total),
                backgroundColor: '#ED2B2A',
                borderColor: '#ED2B2A',
                borderWidth: 1,
            },
        ],
    };


    const net_SalesProcessing = {
        labels: "Net Sale Processing",
        datasets: [
            {
                label: 'Net Sales Processing',
                data: data.net_sales_processing?.map(item => item.total),
                backgroundColor: '#C7E8CA',
                borderColor: '#C7E8CA',
                borderWidth: 1,
            },
        ],
    };

    const net_Sales = {
        labels: "Net Sale",
        datasets: [
            {
                label: 'Net Sales ',
                data: data.net_sales?.map(item => item.total),
                backgroundColor: '#577B8D',
                borderColor: '#577B8D',
                borderWidth: 1,
            },
        ],
    };







    return (
        <>
            <section className='' style={{ marginTop: "100px" }}>
                <div className="container">
                    <div className="row g-3">
                        <div className="col-md-9">
                            <div className="row g-3 ">
                                {boxdata.map((item, index) => (
                                    <div key={index} className="col-sm-6 col-lg-4">
                                        <div className="order--card h-100" onClick={(e) => navigate(item.link)} href="">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="card-subtitle d-flex justify-content-between m-0 align-items-center">
                                                    <img src={item.image} alt="dashboard" className="oder--card-icon" />
                                                    <span style={{ fontSize: "15px" }}>{item.title}</span>
                                                </h6>
                                                <span className={`card-title text-3F8CE8 fw-bold`} style={{ color: item.color === "true" ? "#ff6d6d" : "#00aa6d" }}>
                                                    {/* {item.number} */}
                                                    {data[item.type]}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-3">

                            <div className="bg-white shadow p-3 rounded-3">
                                <canvas ref={chartRef} width="200" height="200"></canvas>
                            </div>

                        </div>
                    </div>
                    <div className="row mt-4 gy-4">
                        {/* <div className="col-md-6">
                            <div>
                                <h2>Net Sales by Vendor</h2>
                                <Bar data={barData} />

                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <h2>Net Sales by Date</h2>
                            <Line data={lineData} />
                        </div>
                        <div className="col-md-6">
                            <h2>Net Sales  </h2>
                            <Bar data={net_Sales} />
                        </div>
                        <div className="col-md-6">
                            <h2>Net Sales Pending </h2>
                            <Bar data={net_SalesPending} />
                        </div>
                        <div className="col-md-6">
                            <h2>Net Sales Processing </h2>
                            <Bar data={net_SalesProcessing} />
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default adminLayout(DashPage);








